import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Radio,
    FormControlLabel,
    Grid,
    Paper,
    Typography,
    Box,
    Container
} from '@mui/material';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import {
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { useLocation, Navigate } from 'react-router-dom';

const backgroundImageURL = '/divinemercyanakara.jpg';

// Styled components for enhanced design
const StyledPaper = styled(Paper)`
  padding: 50px;
  background-color: rgba(255,255,255,0.5) !important;
  position: relative;
`;

const FormContainer = styled(Container)`
  background-image: url(${backgroundImageURL});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 50px;
  /* Table Styles */
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  /* Table Header Styles */
  table thead {
    background-color: #f2f2f2;
  }
  
  /* Table Body Styles */
  table tbody tr:nth-child(even) {
    /* background-color: #f2f2f2; */
  }
  
  /* Table Total Row Styles */
  table tbody tr:last-child {
    font-weight: bold;
  }
  
  /* Responsive Table Styles */
  @media screen and (max-width: 600px) {
    .table {
      border: 0;
    }
    .table thead {
      display: none;
    }
    .table tbody, .table tr, .table th, .table td {
      display: block;
      width: 100%;
    }
    .table tr {
      margin-bottom: 15px;
    }
    .table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
    .table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
    }
  }
  
  .table th, .table td, .table thead {
      background-color: rgba(255,255,255,0.7);
  }
`;

const StyledFormControl = styled(FormControl)`
  margin-bottom: 16px;
`;

const SubmitButton = styled(Button)`
`;


const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "rgba(0, 0, 0, 0.87)",
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 400,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "rgba(0, 0, 0, 0.87)" },
            "::placeholder": { color: "rgba(0, 0, 0, 0.87)" },
            border: "1px solid #ccc",
            backgroundColor: "white"
        },
        invalid: {
            iconColor: "#fa0000",
            color: "#ffc7ee",
        },
    },
};

const RegisterForm = () => {
    const location = useLocation();
    const { firstName, lastName, email, phone } = location.state || {};
    const isInformationPresent = firstName && lastName && email && phone;
    const [rooms, setRooms] = useState(1);
    const [roomShare, setRoomShare] = useState(0);
    const [pinNumber, setPinNumber] = useState('');
    const [street1, setStreet1] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [CurrentData, setCurrentData] = useState([]);
    const [emergencyContactNumber, setEmergencyContactNumber] = useState('');
    const [emergencyContactPersonName, setEmergencyContactPersonName] = useState('');
    const [specialRequest, setSpecialRequest] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalCost, setTotalCost] = useState(0);
    const [finalCost, setFinalCost] = useState(0);
    const [RoomPrice, setRoomPrice] = useState(200);
    const [RoomCharges, setRoomCharges] = useState(0);

    const [paymentMethod, setPaymentMethod] = useState('check');


    const [numberOfMembers, setNumberOfMembers] = useState(1);
    const [numberOfAdults, setNumberOfAdults] = useState(0);
    const [numberOfTeens, setNumberOfTeens] = useState(0);
    const [numberOfChildren, setNumberOfChildren] = useState(0);
    const [numberOfToddlers, setNumberOfToddlers] = useState(0);
    const [numberOfInfants, setNumberOfInfants] = useState(0);

    const [participantCount, setParticipantCount] = useState(1);
    const [formData, setFormData] = useState([
        { relation: 'Myself', age: '', gender: '', firstName: firstName, lastName: lastName, email: email, phone: phone, comments: '' },
    ]);


    useEffect(() => {
        setTimeout( function(){
            axios.get(`${process.env.REACT_APP_API_URL}/api/groupsWithParticipantsByPhoneAndPin?phoneNumber=${formData[0].phone.replace(/\+/g, '')}&pinNumber=${pinNumber}`)
            .then(response => {
                setCurrentData(response.data.groupWithParticipants);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        }, 1500);
    }, [formSubmitted]);

    useEffect(() => {
        const sendEmail = async () => {
            // Retrieve templateParams from localStorage
            const templateParams = JSON.parse(localStorage.getItem('templateParams'));
            // Clear localStorage
            localStorage.removeItem('templateParams');
            // Parse URL parameters to get payment intent
            const urlParams = new URLSearchParams(window.location.search);
            const paymentIntent = urlParams.get('payment_intent');

            if (paymentIntent && templateParams) {
                // Show success alert
                alert('Payment successful!');

                try {
                    // Send email using emailjs
                    const emailResponse = await emailjs.send('service_v82azqs', 'template_oaep9dk', templateParams, {
                        publicKey: 'hW_p-4bygZnZcDdbb',
                    });
                    setFormSubmitted(true);
                    console.log('Email sent successfully:', emailResponse);


                } catch (error) {
                    console.error('Error sending email:', error);
                }
            }
        };

        sendEmail();
    }, []);

    const stripe = useStripe();
    const elements = useElements();
    /*&const [cardElement, setCardElement] = useState(null);
    const handleCardElementChange = (element) => {
        setCardElement(element);
    };*/

    const handlePaymentMethodChange = (event) => {
        if (event.target.value == 'online') {
            let totalCost1 = 0;
            totalCost1 = totalCost + (totalCost * 3 / 100);
            setFinalCost(totalCost1);
        } else {
            setFinalCost(totalCost);
        }
        setPaymentMethod(event.target.value);
    };


    const handleParticipantCountChange = (event) => {
        const count = parseInt(event.target.value, 10);
        setParticipantCount(count);

        // Initialize form data with default values for new participants
        const newFormData = Array.from({ length: count }, (_, index) => ({
            relation: index === 0 ? 'Myself' : '', // 'Myself' is fixed for the first participant
            age: '',
            gender: '',
            firstName: index === 0 ? firstName : '',
            lastName: index === 0 ? lastName : '',
            email: index === 0 ? email : '',
            phone: index === 0 ? phone : '',
            comments: '',
        }));

        setFormData(newFormData);
        UpdatePrice(newFormData, rooms, roomShare);
    };

    const roomChange = (e) => {
        const newRooms = e.target.value;
        setRooms(newRooms);
        UpdatePrice(formData, newRooms, roomShare);
    };

    const setRoomShareChange = (e) => {
        const Share = e.target.value;
        setRoomShare(Share);
        UpdatePrice(formData, rooms, Share);
    };


    const handleInputChange = (index, field, value) => {
        const updatedFormData = [...formData];
        updatedFormData[index][field] = value;
        setFormData(updatedFormData);
        UpdatePrice(updatedFormData, rooms, roomShare);
    };
    const UpdatePrice = (updatedFormData, rooms, roomShare) => {
        let totalCost = 0;
        /*
        const nOfMembers = updatedFormData.filter(person => person.age >= 0).length;
        const nOfAdults = updatedFormData.filter(person => person.age >= 18).length;
        const nOfTeens = updatedFormData.filter(person => person.age < 18 && person.age >= 13).length;
        const nOfChildren = updatedFormData.filter(person => person.age < 13 && person.age >= 5).length;
        const nOfToddlers = updatedFormData.filter(person => person.age < 5 && person.age >= 3).length;
        const nOfInfants = updatedFormData.filter(person => person.age < 3).length;
*/

        const nOfMembers = updatedFormData.filter(person => person.age >= 0).length;
        const nOfAdults = updatedFormData.filter(person => person.age >= 19).length;
        //const nOfTeens = updatedFormData.filter(person => person.age < 0 && person.age >= 0).length;
        const nOfChildren = updatedFormData.filter(person => person.age < 19 && person.age >= 5).length;
        const nOfToddlers = updatedFormData.filter(person => person.age < 5).length;
        const nOfInfants = updatedFormData.filter(person => person.age < 0).length;

        setNumberOfMembers(nOfMembers);
        setNumberOfAdults(nOfAdults);
        //setNumberOfTeens(nOfTeens);
        setNumberOfChildren(nOfChildren);
        setNumberOfToddlers(nOfToddlers);
        setNumberOfInfants(nOfInfants);
        let roomPrice = RoomPrice;

        roomPrice = rooms * roomPrice;

        /*
        if (roomShare == 1 && nOfMembers == 1 && rooms == 1) {
            roomPrice = RoomPrice / 2;
        } else {
            setRoomShare(0);
        }
        setRoomCharges(roomPrice);
        if (nOfMembers === 1) {
            totalCost = roomPrice + nOfAdults * 200;
        }
        else {
            totalCost = roomPrice + nOfAdults * 200 + nOfTeens * 150 + nOfChildren * 75 + nOfToddlers * 50;
        }
        */
        /* Need to verify the charges...*/
        /*
        if (roomShare === 1) {
            if ( nOfMembers === 1 ) {
                totalCost = 385
            } else if ( nOfMembers === 2) {
                totalCost = 385 * nOfMembers
            } else if (nOfAdults > 1 && nOfChildren >= 1 ) {
                totalCost = 385 * 2 + (nOfAdults - 2) * 230 + (nOfChildren) * 210
            } else if (nOfChildren >1) {
                totalCost = 385 * 2 + (nOfAdults - 1) * 230 + (nOfChildren - 1) * 210
            }
        }else{
            if (nOfMembers === 1) {
                // Single person with no sharing: $585 (385 + 200)
                totalCost = 385 + roomPrice;
            }
        }
            */
        //setRoomCharges(roomPrice);
        if (roomShare == 1) {
            // Handle room sharing scenario if needed
            if (nOfAdults == 1 && nOfChildren == 0) {
                // Single person with no sharing: $585 (385 + 200)
                totalCost = 385;
            } else if (nOfAdults == 1 && nOfChildren >= 1) {
                // Single person with no sharing: $585 (385 + 200)
                totalCost = 770 + (nOfChildren - 1) * 210
            } else if (nOfAdults >= 2 && nOfChildren >= 0) {
                // Two participants: $770 (385 each)
                //totalCost = nOfMembers * 385;
                totalCost = 770 + ((nOfAdults - 2) * 230) + (nOfChildren * 210)
            }
        } else {
            if (nOfAdults == 1 && nOfChildren == 0) {
                // Single person with no sharing: $585 (385 + 200)
                totalCost = 385 + 200;
            } else if (nOfAdults == 1 && nOfChildren >= 1) {
                // Single person with no sharing: $585 (385 + 200)
                totalCost = 770 + (nOfChildren - 1) * 210
            } else if (nOfAdults >= 2 && nOfChildren >= 0) {
                // Two participants: $770 (385 each)
                //totalCost = nOfMembers * 385;
                totalCost = 770 + ((nOfAdults - 2) * 230) + (nOfChildren * 210)
            }
        }

        {/*totalCost = 2.00;*/ }


        totalCost = totalCost;

        setTotalCost(totalCost);

        /*if (paymentMethod === 'online') {
            totalCost = totalCost + (totalCost * 3 / 100)
        }*/
        setFinalCost(totalCost);
    }

    // Generate HTML content for member list
    const generateMemberListHTML = () => {
        return (
            `<ul>
            ${formData.map((participant, index) => (
                `<li key=${index}>
                <strong>Name:</strong> ${participant.firstName} ${participant.lastName} - 
                <strong>Email:</strong> ${participant.email} -
                <strong>Phone:</strong> ${participant.phone} -
                <strong>Age:</strong> ${participant.age}
            </li>`
            )).join('')}
        </ul>`
        );
    };

    const generateChargesHTML = () => {
        return (
            `<table class="table">
                <thead>
                    <tr>
                        <th>Charge Details</th>
                        <th>Charges</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="Charge Details">Adult (${numberOfAdults})</td>
                        <td data-label="Charges">

                            <p dangerouslySetInnerHTML={{__html: calculateFee(numberOfAdults, roomShare) }} />

                            ${calculateFee(numberOfAdults, roomShare)}

                        </td>
                    </tr>
                    <tr>
                        <td data-label="Charge Details">Children (${numberOfChildren})</td>
                        <td data-label="Charges">${calculateFeeChildren(numberOfAdults, numberOfChildren, roomShare)}</td>
                    </tr>
                    ${paymentMethod === 'online' ? `
                        <tr>
                            <td data-label="Charge Details" style="color: red;">Credit Card Fee</td>
                            <td data-label="Charges" style="color: red;">${(totalCost * 0.03).toFixed(2)}</td>
                        </tr>` : ''}
                    <tr>
                        <td data-label="Charge Details"><strong>Total Charges</strong></td>
                        <td data-label="Charges"><strong>${finalCost.toFixed(2)}</strong></td>
                    </tr>
                </tbody>
            </table>`
        );
    };

    const calculateFee = (numberOfAdults, roomShare) => {

        console.log(numberOfAdults, roomShare)
        if (numberOfMembers >= 2) {
            roomShare = 1
        }
        if (numberOfAdults == 1 && roomShare == 0) {
            return (385 + 200).toFixed(2); // 385 + 200
        } else if (numberOfAdults == 1 && roomShare == 1) {
            return (385).toFixed(2); // Only 1 adult, no room sharing
        } else if (numberOfAdults >= 2) {
            let baseFee = 385 * 2; // Base fee for the first two adults
            let additionalAdultsFee = (numberOfAdults - 2) * 230; // Fee for additional adults

            return (baseFee + additionalAdultsFee).toFixed(2);
        } else {
            return "0.00"; // Default case
        }
    };

    const calculateFeeChildren = (numberOfAdults, numberOfChildren, roomShare) => {
        if (numberOfAdults == 1 && numberOfChildren == 1) {
            return (385).toFixed(2); // 385 + 200
        } else if (numberOfAdults == 1 && numberOfChildren >= 1) {
            //return (210 * numberOfChildren).toFixed(2); // Two adults and x number of children
            return (385 + (210 * (numberOfChildren - 1))).toFixed(2);
        } else if (numberOfAdults >= 1 && numberOfChildren >= 1) {
            //return (210 * numberOfChildren).toFixed(2); // Two adults and x number of children
            return (210 * numberOfChildren).toFixed(2);
        } else {
            return "0.00"; // Default case
        }
    };

    const handleSubmit = async () => {
        // Email and phone validation patterns
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^\+?([0-9]{1,3})?[-. ]?(\([0-9]{1,3}\)|[0-9]{1,3})[-. ]?([0-9]{1,4})[-. ]?([0-9]{1,4})[-. ]?([0-9]{1,4})$/;

        // Basic validation for required fields and format checks
        for (let i = 0; i < participantCount; i++) {
            const participant = formData[i];
            if (
                (i === 0 && !participant.email.trim()) ||
                (i === 0 && !participant.phone.trim())
            ) {
                console.error('Myself email and phone fields are required.');
                alert('Myself email and phone fields are required. Please fill out all fields.');
                return;
            }

            if (
                !participant.firstName.trim() ||
                !participant.lastName.trim() ||
                !participant.relation ||
                //!participant.email.trim() ||
                //!participant.phone.trim() ||
                !participant.age
            ) {
                console.error('All fields are required.');
                alert('Please fill out all fields.');
                return;
            }

            if (i === 0 && !emailPattern.test(participant.email)) {
                console.error('Invalid email format..');
                alert('Invalid email format for one or more entries.');
                return;
            }

            if (i === 0 && !phonePattern.test(participant.phone)) {
                console.error('Invalid phone format...');
                alert('Invalid phone format for one or more entries.');
                return;
            }

            // Additional validation for "Myself" participant's age
            if (i === 0 && parseInt(participant.age, 10) < 19) {
                console.error('Age must be 19 or above for "Myself".');
                alert('Age must be 19 or above for "Myself".');
                return;
            }
        }

        // Validation
        if (!city || !postalCode || !state) {
            alert('Address fields are required.');
            return;
        }
        if (!pinNumber) {
            alert('Pin Number field are required.');
            return;
        }

        setLoading(true);
        try {
            const newaddress = `${street1}, ${city}, ${state}, ${postalCode}, US`;
            let paymentInfo = {};
            if (paymentMethod === 'online') {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: "card",
                    card: elements.getElement(CardElement),
                    billing_details: {
                        address: {
                            city: city,
                            country: "US",
                            line1: street1,
                            line2: null,
                            postal_code: postalCode,
                            state: state
                        },
                        email: formData[0].email,
                        name: formData[0].firstName,
                        phone: formData[0].phone
                    },
                });

                if (error) {
                    throw new Error(error.message);
                }

                const { id } = paymentMethod;
                paymentInfo.id = id;
            }
            paymentInfo.amount = finalCost * 100;
            paymentInfo.paymentMethod = paymentMethod;
            //paymentInfo.return_url = "https://graceanointing.org/success?email=" + formData[0].email;
            paymentInfo.return_url = `${process.env.REACT_APP_URL}/success?email=${formData[0].email}`;

            // Send email using emailjs
            var templateParams = {
                name: formData[0].firstName + ' ' + formData[0].lastName,
                email: formData[0].email,
                retreat_name: " Chicago Krupabhishekam Residential ",
                members: generateMemberListHTML(),
                charges: generateChargesHTML(),
                reply_to: "retreat@graceanointing.org"
            };

            // Serialize templateParams object
            const serializedParams = JSON.stringify(templateParams);

            // Store serializedParams in localStorage
            localStorage.setItem('templateParams', serializedParams);

            //const response = await axios.post('http://localhost:5000/backend/api/createGroupWithParticipants', {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/createGroupWithParticipants`, {
                groupData: {
                    email: formData[0].email,
                    phone: formData[0].phone,
                    pinNumber: pinNumber,
                    numberOfParticipants: participantCount,
                    address: newaddress,
                    shareRoom: roomShare,
                    rooms: rooms,
                    amount: finalCost,
                    specialRequest: specialRequest,
                    emergencyContactPersonName: emergencyContactPersonName,
                    emergencyContactNumber: emergencyContactNumber
                },
                participantData: formData,
                paymentInfo: paymentInfo
            });

            if (response.data.payment && response.data.payment.status == "requires_action" && response.data.payment.next_action.redirect_to_url && response.data.payment.next_action.redirect_to_url.url) {
                window.location.replace(response.data.payment.next_action.redirect_to_url.url);
                return true;
            }

            const emailResponse = await emailjs.send('service_v82azqs', 'template_oaep9dk', templateParams, {
                publicKey: 'hW_p-4bygZnZcDdbb',
            });

            console.log('Email sent successfully:', emailResponse);

            window.history.replaceState({}, '');
            setFormSubmitted(true);
            setLoading(false);
        } catch (error) {
            console.error('Error submitting form:', error);
            let errorMessage = 'An error occurred while submitting the form. Please try again later.';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            } else if (error.message) {
                errorMessage = error.message;
            }
            alert(errorMessage);
            setLoading(false);
        }
    };

    if (!isInformationPresent) {
        // Redirect to homepage if required information is not present
        //return <Navigate to="/" replace />;
    }

    return (
        <FormContainer component="main" maxWidth="xl">
            <StyledPaper elevation={2}>

                {formSubmitted ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center" sx={{ color: 'maroon' }} gutterBottom>
                                "For I know well the plans I have in mind for you, delcares the LORD plans for your great future and not for woe, so as to give you a future of hope!" Jeremiah 29:11
                            </Typography>
                            <Typography variant="h4" align="center" gutterBottom>
                                -------------------------------------------------------------------------------------------------------------------
                            </Typography>
                            <Typography variant="h4" align="center" gutterBottom>
                                Thank you! Your registration for Chicago Residential Retreat has been received. Use this link to join the WhatsApp group: https://chat.whatsapp.com/HjrW9zbQ8uUDpI1V4UqJ5k
                                <br />
                                Registration will be confirmed upon payment verification, and you will receive a confirmation email at {formData[0].email}.
                            </Typography>
                            <Typography variant="h4" align="center" gutterBottom>
                                -------------------------------------------------------------------------------------------------------------------
                            </Typography>

                            <Typography variant="h4" align="center" gutterBottom>
                                If you have any questions, pelase email info@graceanointing.org
                            </Typography>
                        </Grid>

                      {/*  <div className='bg-white p-5 w-100'>
                            <table className="styled-table">
                        <thead>
                            <tr>
                                <th>Group Leader</th>
                                <th>Leader Email</th>
                                <th>Members</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Cell</th>
                                <th>Gender</th>
                                <th>Age</th>
                                <th>Relationship</th>
                                <th>Total Cost</th>
                                <th>Comments</th> 
                                <th>Approval</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CurrentData?.participants?.map((member) => (
                                    //member?.deleted1 ? null :
                                        <tr key={member._id} style={{ backgroundColor: CurrentData?.status === 'Approved' ? '#9dd49d' : 'inherit' }}>
                                            
                                            <td data-label="Group Leader">
                                                {CurrentData?.participants.filter((participant) => participant.relation === 'Myself').map((myself) => `${myself.firstName} ${myself.lastName}`)}
                                            </td>
                                            <td data-label="Leader Email">{CurrentData?.email}</td>
                                            <td data-label="Members">{CurrentData?.participants.filter(participant => !participant.deleted).length}</td>
                                            <td data-label="Name">{member.firstName} {member.lastName}</td>
                                            <td data-label="Email">{member.email}</td>
                                            <td data-label="Cell">{member.phone}</td>
                                            <td data-label="Gender">{member.gender}</td>
                                            <td data-label="Age">{member.age}</td>
                                            <td data-label="Relationship">{member.relation}</td>
                                            <td data-label="Total Cost">{CurrentData?.amount}</td>
                                            <td data-label="Comments">{member.comments || ''}</td>
                                            <td>
                                                {CurrentData?.status === 'Approved' ? 'Approved' : 'Pending'}
                                            </td>
                                        </tr>
                                ))}
                        </tbody>
                        </table>
                        </div> */}

                    </Grid>
                ) : (<Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" sx={{ color: 'maroon' }} gutterBottom>
                            "But seek first the kingdom of God and his righteousness, and all these things will be given to you besides." Mathew 6:33
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" align="center" gutterBottom>
                            Chicago Retreat 2024 Registration
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" sx={{ color: 'red', fontStyle: 'italic' }} gutterBottom>
                            Led By FR. DOMINIC VALANMANAL
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" sx={{ color: 'red', fontStyle: 'italic' }} gutterBottom>
                            From Nov 21st Thursday 4:00pm to 24th Sunday 2:00pm
                        </Typography>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <p style={{ color: 'purple' }}>VENUE: Hilton Chicago/Northbrook (2855 Milwaukee Ave, Northbrook, IL 60062). There will be separate sessions for Children.</p>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={2}>
                            <p>Step 1: Select number of participants</p>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <p>Step 2: Choose a unique pin number like "1234567"</p>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <p>Step 3: Enter participant(s) details</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p>Step 4: Enter address, emergency details, special requests, choose your payment method and press <span style={{ backgroundColor: '#1976d2', color: 'white' }}>SUBMIT</span> below.</p>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <StyledFormControl fullWidth style={{ marginBottom: '20px' }}>
                            <InputLabel>Select Number of Participants</InputLabel>
                            <Select label="Select Number of Participants" value={participantCount} onChange={handleParticipantCountChange}>
                                {[1, 2, 3, 4, 5, 6].map((count) => (
                                    <MenuItem key={count} value={count}>
                                        {count}
                                    </MenuItem>
                                ))}
                            </Select>
                        </StyledFormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth><TextField
                            label="Pin Number"
                            fullWidth
                            error={pinNumber?.length === 0}
                            value={pinNumber}
                            onChange={(e) => setPinNumber(e.target.value)}
                            margin="dense"
                        /></FormControl>
                    </Grid>

                    {/*
                    <Grid item xs={4}>
                        <StyledFormControl fullWidth style={{ marginBottom: '20px' }}>
                            <InputLabel>No. of Rooms</InputLabel>
                            <Select label="No. of Rooms" value={rooms} onChange={roomChange}>
                                {[1, 2, 3, 4, 5, 6, 7, 8].map((count) => (
                                    <MenuItem key={count} value={count}>
                                        {count}
                                    </MenuItem>
                                ))}
                            </Select>
                        </StyledFormControl>
                    </Grid>
                            */}
                    {/*
                    {numberOfMembers == 1 && rooms == 1 && <Grid item xs={4}>
                        */}
                    {numberOfMembers == 1 && <Grid item xs={4}>
                        <StyledFormControl fullWidth style={{ marginBottom: '20px' }}>
                            <InputLabel>Share Room</InputLabel>
                            <Select label="Share Room" value={roomShare} onChange={setRoomShareChange}>
                                <MenuItem value='0'>
                                    No
                                </MenuItem>
                                <MenuItem value='1'>
                                    Yes
                                </MenuItem>
                            </Select>
                        </StyledFormControl>
                    </Grid>}

                    {formData.map((data, index) => (
                        <Grid container key={index} spacing={2}>
                            <Grid item xs={12} sm={2}>
                                <FormControl fullWidth><TextField
                                    error={data.firstName?.length === 0}
                                    disabled={index === 0}
                                    label="First Name"
                                    fullWidth
                                    value={data.firstName}
                                    margin="dense"
                                    onChange={(e) => handleInputChange(index, 'firstName', e.target.value)}
                                /></FormControl>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <FormControl fullWidth><TextField
                                    error={data.lastName?.length === 0}
                                    disabled={index === 0}
                                    label="Last Name"
                                    fullWidth
                                    value={data.lastName}
                                    onChange={(e) => handleInputChange(index, 'lastName', e.target.value)}
                                    margin="dense"
                                /></FormControl>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <FormControl fullWidth><TextField
                                    disabled={index === 0}
                                    label="Email"
                                    fullWidth
                                    type="email"
                                    value={data.email}
                                    margin="dense"
                                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}

                                /></FormControl>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <FormControl fullWidth><TextField
                                    disabled={index === 0}
                                    label="Cell(xxx-xxx-xxxx)"
                                    fullWidth
                                    type="tel"
                                    value={data.phone}
                                    margin="dense"
                                    onChange={(e) => handleInputChange(index, 'phone', e.target.value)}

                                /></FormControl>
                            </Grid>

                            <Grid item xs={12} sm={1}>
                                <FormControl fullWidth error={data.gender?.length === 0}>
                                    <InputLabel>Gender</InputLabel>
                                    <Select
                                        value={data.gender}
                                        label="Gender"
                                        style={{ marginTop: '8px' }}
                                        onChange={(e) => handleInputChange(index, 'gender', e.target.value)}
                                    >
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={1}>
                                <FormControl fullWidth error={data.age?.length === 0}>
                                    <InputLabel>Age</InputLabel>
                                    <Select
                                        value={data.age}
                                        label="Age"
                                        style={{ marginTop: '8px' }}
                                        onChange={(e) => handleInputChange(index, 'age', e.target.value)}
                                    >
                                        {[...Array(100).keys()].map((age) => (
                                            <MenuItem key={age} value={age}>
                                                {age}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={2}>

                                {index === 0 ? (<FormControl fullWidth><TextField
                                    error={data.relation?.length === 0}
                                    label="Relation"
                                    fullWidth
                                    value={data.relation}
                                    disabled={index === 0}
                                    margin="dense"
                                    onChange={(e) => handleInputChange(index, 'relation', e.target.value)}
                                /></FormControl>) : (<FormControl fullWidth error={data.relation?.length === 0}>
                                    <InputLabel>Relation</InputLabel>
                                    <Select
                                        value={data.relation}
                                        label="Relation"
                                        style={{ marginTop: '8px' }}
                                        onChange={(e) => handleInputChange(index, 'relation', e.target.value)}
                                    >
                                        <MenuItem value="Wife">Wife</MenuItem>
                                        <MenuItem value="Husband">Husband</MenuItem>
                                        <MenuItem value="Mother">Mother</MenuItem>
                                        <MenuItem value="Father">Father</MenuItem>
                                        <MenuItem value="Son">Son</MenuItem>
                                        <MenuItem value="Daughter">Daughter</MenuItem>
                                        <MenuItem value="Brother">Brother</MenuItem>
                                        <MenuItem value="Sister">Sister</MenuItem>
                                        <MenuItem value="Grand Father">Grand Father</MenuItem>
                                        <MenuItem value="Grand Mother">Grand Mother</MenuItem>
                                        <MenuItem value="Grand Son">Grand Son</MenuItem>
                                        <MenuItem value="Grand Daughter">Grand Daughter</MenuItem>
                                    </Select>
                                </FormControl>)}

                            </Grid>
                        </Grid>
                    ))}

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth  ><TextField
                                error={street1?.length === 0}
                                label="Street Address"
                                fullWidth
                                value={street1}
                                onChange={(e) => setStreet1(e.target.value)}
                                margin="dense"
                            /></FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth  ><TextField
                                label="City"
                                fullWidth
                                error={city?.length === 0}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                margin="dense"
                            /></FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth  ><TextField
                                label="State"
                                error={state?.length === 0}
                                fullWidth
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                margin="dense"
                            /></FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl fullWidth  ><TextField
                                label="Zip Code"
                                error={postalCode?.length === 0}
                                fullWidth
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                margin="dense"
                            /></FormControl>
                        </Grid>
                        {/*<Grid item xs={12} sm={3}>
                            <FormControl fullWidth  ><TextField
                                label="Country (US, GB)"
                                fullWidth
                                value={country}
                                error={country?.length === 0}
                                onChange={(e) => setCountry(e.target.value)}
                                margin="dense"
                            /></FormControl>
                        </Grid>
                */}
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth  ><TextField
                                label="Emergency Contact Person Name"
                                fullWidth
                                //error={emergencyContactPersonName?.length === 0}
                                value={emergencyContactPersonName}
                                onChange={(e) => setEmergencyContactPersonName(e.target.value)}
                                margin="dense"
                            /></FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth  ><TextField
                                label="Emergency Contact Number"
                                fullWidth
                                //error={emergencyContactNumber?.length === 0}
                                value={emergencyContactNumber}
                                onChange={(e) => setEmergencyContactNumber(e.target.value)}
                                margin="dense"
                            /></FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth  ><TextField
                                label="Special Requests (Max. Characters: 1000)"
                                fullWidth
                                //error={specialRequest?.length === 0}
                                //the value column needs to be changed after changing the schema
                                value={specialRequest}
                                onChange={(e) => setSpecialRequest(e.target.value)}
                                margin="dense"
                            /></FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div style={{ padding: "10px" }}>
                                <FormControlLabel
                                    value="check"
                                    control={<Radio color="primary" />}
                                    label="Check"
                                    checked={paymentMethod === 'check'}
                                    onChange={handlePaymentMethodChange}
                                />

                                <FormControlLabel
                                    value="zelle"
                                    control={<Radio color="primary" />}
                                    label="Zelle"
                                    checked={paymentMethod === 'zelle'}
                                    onChange={handlePaymentMethodChange}
                                />

                                {/*<FormControlLabel
                                    value="online"
                                    control={<Radio color="primary" />}
                                    label="Online"
                                    checked={paymentMethod === 'online'}
                                    onChange={handlePaymentMethodChange}
                                />*/}

                                {paymentMethod === 'check' && (
                                    <div>
                                        {/* Show information for Check */}
                                        <Grid item xs={12} sm={12}>
                                            <p>If you choose to pay by check, please make it payable to "Knanaya Catholic Region" and include your "Name and Phone Number" on it. Then, send the check to the following address: <p style={{ color: 'red' }}>Knanaya Catholic Region, 541 N Willow Rd, Elmhurst, IL 60126</p></p>
                                            {/*
                                            <p>If you choose to pay by check, please make it payable to "Grace Anointing USA" and include your "Name and Phone Number" on it. Then, send the check to the following address: <p style={{ color: 'red' }}>Grace Anointing, 3001 Sunrise Run Ln, Pearland, TX 77584. To finish your registration, please click the Submit Button</p></p>

                                            A credit card fee of 3% will be added to the total if you choose the "online" option.
                                */}
                                        </Grid>
                                    </div>
                                )}

                                {paymentMethod === 'zelle' && (
                                    <div>
                                        <Grid item xs={12} sm={12}>
                                            {/* Show information for Zelle */}
                                            {/*                                            <p>For payments via Zelle, please transfer funds to <p style={{ color: 'purple' }}>"info@graceanointing.org"</p> and ensure your <p style={{ color: 'red' }}>"Name and Phone Number"</p> are included in the details. To finish your registration, please click the SUBMIT button.</p> */}
                                            <p>For payments via Zelle, please transfer funds to <p style={{ color: 'purple' }}>"retreat@knanayaregion.org"</p> and ensure your <p style={{ color: 'red' }}>"Name and Phone Number"</p> are included in the details. To finish your registration, please click the SUBMIT button.</p>
                                        </Grid>
                                    </div>
                                )}

                                {paymentMethod === 'online' && (
                                    <div>
                                        {/* Show CardElement for online payment */}
                                        <CardElement options={CARD_OPTIONS} />
                                        <Grid item xs={12} sm={12}>
                                            <p>A credit card fee of <p style={{ color: 'red' }}>3%</p> will be added to the total if you choose the "online" option.</p>
                                        </Grid>
                                    </div>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={6}>

                            <div dangerouslySetInnerHTML={{ __html: generateChargesHTML() }} />

                            <div className="price-notes">
                                <h5>Fee Details</h5>
                                <div className="section">

                                    <ul>
                                        <li>Single Person  with no sharing: 385+200=$585</li>
                                        <li className="sublist">
                                            <strong>Shared Accommodation:</strong>
                                            <ul>
                                                <li>One participant w/ shared room $385 </li>
                                                <li>First 2 participants (Adult/Kid) $385 each</li>
                                                <li>Additional Adults $230 each</li>
                                                <li>Additional Kids (5-18yrs) $210 each</li>
                                                <li>Kids under 5 yrs may attend free of charge</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </Grid>
                        {/*<Grid item xs={12}>
                            <Typography variant="body2" color="textPrimary" align="right"><strong>Total Charges:</strong> ${finalCost}</Typography>
                        </Grid>*/}
                        <Grid item xs={12}>
                            <SubmitButton
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Submit'}
                            </SubmitButton>
                        </Grid>
                    </Grid>
                </Grid>
                )}
                <Box mt={4}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        © 2024 GraceAnointing All rights reserved.
                    </Typography>
                </Box>
            </StyledPaper>
        </FormContainer>
    );
};

export default RegisterForm;